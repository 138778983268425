// Material
import { createStyles, makeStyles } from '@material-ui/core';

// Styles
const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      '#root': {
        height: '100%',
        width: '100%'
      }
    }
  })
);

const GlobalStyles = () => {
  useStyles();

  return null;
};

export default GlobalStyles;

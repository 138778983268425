import { FC } from 'react';

// Common
import Page from 'app/common/page';

// Material
import { Box, Container, Typography, makeStyles } from '@material-ui/core';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  image: {
    marginTop: 50,
    display: 'inline-block',
    maxWidth: '100%',
    width: 560
  }
}));

const NotFound: FC = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="404">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="md">
          <Typography align="center" color="textPrimary" variant="h1">
            404: La page que vous recherchez n'est pas ici.
          </Typography>
          <Typography align="center" color="textPrimary" variant="subtitle2">
            Soit vous avez essayé une route louche, soit vous êtes venu ici par erreur. Quoi qu'il en soit, essayez
            d'utiliser la navigation.
          </Typography>
          <Box textAlign="center">
            <img alt="404" className={classes.image} src="/static/images/vectors/not_found.svg" />
          </Box>
        </Container>
      </Box>
    </Page>
  );
};

export default NotFound;

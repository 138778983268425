import { FC, useState } from 'react';

// Common
import Page from 'app/common/page';

// Externals
import { Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { useAuth } from 'reactfire';
import * as yup from 'yup';

// Material
import { Box, Button, CircularProgress, Container, TextField, Typography, makeStyles } from '@material-ui/core';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  circularProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -16,
    marginLeft: -16
  }
}));

interface States {
  email: string;
  password: string;
}

const Login: FC = () => {
  const classes = useStyles();

  const auth = useAuth();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [initialValues] = useState<States>({
    email: '',
    password: ''
  });

  const onSubmit = async (values: { email: string; password: string }) => {
    try {
      const result = await auth.signInWithEmailAndPassword(values.email, values.password);

      const key = enqueueSnackbar(`Bonjour, ${result.user?.email} !`, {
        variant: 'success',
        onClick: () => {
          closeSnackbar(key);
        }
      });
    } catch (error) {
      switch (error.code) {
        case 'auth/user-not-found':
          const userNotFoundKey = enqueueSnackbar(`Aucun utilisateur correspondant à cet identifiant.`, {
            variant: 'error',
            onClick: () => {
              closeSnackbar(userNotFoundKey);
            }
          });
          break;
        case 'auth/wrong-password':
          const wrongPasswordKey = enqueueSnackbar(`Le mot de passe n'est pas valide.`, {
            variant: 'error',
            onClick: () => {
              closeSnackbar(wrongPasswordKey);
            }
          });
          break;
        case 'auth/too-many-requests':
          const tooManyRequestsKey = enqueueSnackbar(
            `L'accès à ce compte a été temporairement désactivé en raison de nombreuses tentatives de connexion infructueuses. Veuillez contacter votre support technique ou réessayer plus tard.`,
            {
              variant: 'error',
              onClick: () => {
                closeSnackbar(tooManyRequestsKey);
              }
            }
          );
          break;
        default:
          const unknownKey = enqueueSnackbar(
            `Une erreur inconnue est survenue lors de la connexion. Veuillez réessayer ultérieurement.`,
            {
              variant: 'error',
              onClick: () => {
                closeSnackbar(unknownKey);
              }
            }
          );
          break;
      }
    }
  };

  return (
    <Page className={classes.root} title="Connexion">
      <Box display="flex" flexDirection="column" height="100%" justifyContent="center">
        <Container maxWidth="sm">
          <Formik
            initialValues={initialValues}
            isInitialValid={false}
            enableReinitialize={true}
            validateOnMount={true}
            validationSchema={yup.object().shape({
              email: yup
                .string()
                .email(`L'adresse e-mail n'est pas valide.`)
                .required(`L'adresse e-mail est nécessaire.`),
              password: yup.string().required(`Le mot de passe est nécessaire.`)
            })}
            onSubmit={onSubmit}
          >
            {({ handleBlur, handleChange, handleSubmit, errors, touched, values, isSubmitting, isValid }) => (
              <form onSubmit={handleSubmit}>
                <Box>
                  <Typography color="textPrimary" variant="h2">
                    Se connecter
                  </Typography>
                  <Typography color="textSecondary" gutterBottom variant="body2">
                    Connectez-vous à la plateforme interne
                  </Typography>
                </Box>
                <TextField
                  disabled={isSubmitting}
                  error={Boolean(touched.email && errors.email)}
                  fullWidth={true}
                  helperText={touched.email && errors.email}
                  label="Adresse e-mail"
                  margin="normal"
                  name="email"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="email"
                  value={values.email}
                  variant="outlined"
                />
                <TextField
                  disabled={isSubmitting}
                  error={Boolean(touched.password && errors.password)}
                  fullWidth={true}
                  helperText={touched.password && errors.password}
                  label="Mot de passe"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2} position="relative">
                  <Button
                    color="primary"
                    disabled={isSubmitting || !isValid}
                    fullWidth={true}
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Connexion
                  </Button>
                  {isSubmitting && (
                    <CircularProgress className={classes.circularProgress} color="secondary" size={32} />
                  )}
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default Login;

import { FC } from 'react';

// Externals
import clsx from 'clsx';
import { Link as RouterLink } from 'react-router-dom';

// Material
import { AppBar, Box, Toolbar, makeStyles } from '@material-ui/core';

// Styles
const useStyles = makeStyles({
  root: {},
  toolbar: {
    height: 64
  }
});

interface Props {
  className?: string;
}

const TopBar: FC<Props> = ({ className }) => {
  const classes = useStyles();

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0}>
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img alt="Logo" src="static/icons/logo.png" height="48px" width="48px" />
          </Box>
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;

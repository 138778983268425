import { FC } from 'react';

// Externals
import _ from 'lodash';

// Material
import { Box, Divider, Link, List, Typography, makeStyles } from '@material-ui/core';

// Material Lab
import Skeleton from '@material-ui/lab/Skeleton';

// Styles
const useStyles = makeStyles((theme) => ({
  avatar: {
    width: 80,
    height: 80,
    marginBottom: theme.spacing(1)
  },
  icon: {
    width: 20,
    height: 20,
    marginRight: theme.spacing(1)
  },
  title: {
    width: '100%',
    marginRight: 'auto'
  }
}));

interface Props {
  items: {
    href: string;
    icon: any;
    title: string;
  }[];
}

const Shimmer: FC<Props> = ({ items }) => {
  const classes = useStyles();

  return (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Skeleton className={classes.avatar} variant="circle" />
        <Typography color="textPrimary" variant="h5">
          <Skeleton width={120} variant="text" />
        </Typography>
        <Typography color="textSecondary" variant="body2">
          <Skeleton width={80} variant="text" />
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {_.map(items, (_item) => (
            <Box
              display="flex"
              flexDirection="row"
              alignItems="flex-start"
              justifyContent="flex-start"
              py="10px"
              px="8px"
              width="100%"
            >
              <Skeleton className={classes.icon} variant="circle" />
              <Skeleton className={classes.title} variant="text" />
            </Box>
          ))}
        </List>
      </Box>
      <Box flexGrow={1} />
      <Box display="flex" alignItems="center" justifyContent="center" p={2} m={2}>
        <Typography align="center" variant="caption">
          Made with <strong>{'</>'}</strong> by{' '}
          <Link href="https://www.sparkle.tech/" target="_blank">
            Sparkle
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default Shimmer;

import { FC } from 'react';

// Externals
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { useAuth } from 'reactfire';
import { Link as RouterLink } from 'react-router-dom';

// Material
import { AppBar, Box, Hidden, IconButton, Toolbar, makeStyles } from '@material-ui/core';

// Material Icons
import MenuIcon from '@material-ui/icons/Menu';
import InputIcon from '@material-ui/icons/Input';

// Styles
const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    width: 60,
    height: 60
  },
  icon: {
    marginRight: theme.spacing(3)
  }
}));

interface Props {
  className?: string;
  onMobileOpen: () => void;
}

const TopBar: FC<Props> = ({ className, onMobileOpen }) => {
  const classes = useStyles();

  const auth = useAuth();

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const onSignOut = async () => {
    try {
      await auth.signOut();

      const key = enqueueSnackbar(`Au revoir !`, {
        variant: 'success',
        onClick: () => {
          closeSnackbar(key);
        }
      });
    } catch (error) {
      const key = enqueueSnackbar(
        `Une erreur inconnue est survenue lors de la déconnexion. Veuillez réessayer ultérieurement.`,
        {
          variant: 'error',
          onClick: () => {
            closeSnackbar(key);
          }
        }
      );
    }
  };

  return (
    <AppBar className={clsx(classes.root, className)} elevation={0}>
      <Toolbar>
        <Hidden lgUp>
          <IconButton className={classes.icon} color="inherit" onClick={onMobileOpen}>
            <MenuIcon />
          </IconButton>
        </Hidden>
        <RouterLink to="/">
          <Box display="flex" alignItems="center" justifyContent="center">
            <img alt="Logo" src="static/icons/logo.png" height="48px" width="48px" />
          </Box>
        </RouterLink>
        <Box flexGrow={1} />
        <IconButton color="inherit" onClick={onSignOut}>
          <InputIcon />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
